import React, { useState }                from 'react'
import FullCalendar                       from '@fullcalendar/react' // must go before plugins
import { useCalendar }                    from './useCalendar'

// plugins
import interactionPlugin          from "@fullcalendar/interaction"
import resourceTimelinePlugin     from '@fullcalendar/resource-timeline'
import resourceTimeGridPlugin     from '@fullcalendar/resource-timegrid'
import scrollGrid                 from '@fullcalendar/scrollgrid'
import resourceDayGridPlugin      from '@fullcalendar/resource-daygrid'
import resource                   from '@fullcalendar/resource'
import timeGridPlugin             from '@fullcalendar/timegrid'
import daygridplugin              from '@fullcalendar/daygrid'

// helpers
import { CALENDAR_VIEWS, getCurrentView } from '@helpers/javascript/calendar_helper'
import { postRequest, pathname }          from '@helpers/javascript/javascript'
import { modalRequest }                   from '../helpers/fetch'

// Components
import OpenEventsList from './openEventsList'
import EventCard      from './eventCard'
import Combobox       from "@shared/combobox"
import { UserLabel }  from "@shared/calendar/userLabel"

const CalendarRight = () => {
  const { state, setState, TOOLS, filter, setFilter, fetchEvents } = useCalendar()
  const SEPARATED = "separated"
  const [fetching,       setFetching]       = useState()
  const [dropping,       setDropping]       = useState(false)

  const renderLabelContent = resource => {
    return (
      <UserLabel 
        resource = { resource } 
        popupAction = { newState =>
          setState(prevState => ({
            ...prevState,
            showPopup: newState
          }))} 
    />
    )
  }
  
  const handleSelect = e => {
    const path = TOOLS.workorderFromCalendar === 'modal' ? '/calendar/workorders/new' : '/workorders/new'
    const params = {
      date_planned:       e.startStr,
      predicted_duration: (e.end - e.start) / 60000
    }
    if (e.resource) {
      params.technician = e.resource?._resource?.parentId ? e.resource._resource.id : "",
      params.manager    = e.resource?._resource?.parentId ? e.resource._resource.parentId : e.resource._resource.id
    }

    const url = pathname({ path, params })

    if (TOOLS.workorderFromCalendar === 'modal') {
      return modalRequest(url)
    } else { window.location = url } 
  }

  const fullDayView = () => [CALENDAR_VIEWS.assignment, CALENDAR_VIEWS.month].includes(filter.view.name)

  const handleEventResize = (e) => {
    const path   = '/calendar/update_resize'
    const format = "json"
    const body   = {
      event: {
        workorder_id: e.event._def.extendedProps.info.workorder_id,
        start:        e.event._instance.range.start,
        end:          e.event._instance.range.end
      }
    }

    const url = pathname({ path, format })
    postRequest({ url, body }).then(res => {
      if (res.unprocessed) e.revert()
      const newEvents = [...state.events]
      const changedEvent = newEvents.findIndex(ev => res.event.info.workorder_id === ev.info?.workorder_id)
      newEvents[changedEvent] = res.event
      setState(prevState=>({...prevState, events: newEvents}))
    })
  }

  const handleDatesSet = e => {
    if(fetching) clearTimeout(fetching)

    setState(prevState=>({
      ...prevState,
      calendar:    e.view.calendar,
      currentDate: e.view.calendar.currentData.currentDate,
      startDate:   e.start,
      endDate:     e.end
    }))
    setFetching(setTimeout(() => { fetchEvents({start: e.start, end: e.end, callback: (e)=>setState(prevState=>({...prevState, events: e}))}) }, 500))
  }

  const renderEventContent = eventInfo => {
    const absence = eventInfo.event._def.extendedProps.absence_id
    const info    = eventInfo.event._def.extendedProps.info
    if (absence) {
      return (
        <div>{eventInfo.event._def.title}</div>
      )
    } else if (info) {
      return (
        <EventCard
          info             ={info}
          showDate         ={!fullDayView()}
          showDuration     ={fullDayView()}
        />
      )
    }
  }

  const handleInternalDrop = e => {
    const path   = '/calendar/update_drop'
    const format = "json"
    const body   = {
      event: {
        workorder_id: e.event._def.extendedProps.info.workorder_id,
        time_change:  e.delta,
        resourceId:   e.event._def.resourceIds[0]
      }
    }
    
    const url = pathname({ path, format })
    postRequest({ url, body })
    .then((res) => {
      if (res.unprocessed) {
        return e.revert()
      }
      const newEvents = [...state.events]
      const changedEvent = newEvents.findIndex(ev => res.event.info.workorder_id === ev.info?.workorder_id)
      newEvents[changedEvent] = res.event
      setState(prevState=>({...prevState, events: newEvents}))
    })
  }

  const handleExternalDrop = e => {
    if (dropping) return

    setDropping(true)
    const event  = JSON.parse(e.draggedEl.dataset.info)
    const path   = '/calendar/update_drop'
    const format = "json"
    const date   = e.date

    if (fullDayView()) {
      const hours   = event.event_start.split(":")[0]
      const minutes = event.event_start.split(":")[1]
      date.setHours(hours, minutes)
    }

    const body   = {
      event: {
        workorder_id:     event.workorder_id,
        resourceId:       e.resource ? e.resource._resource.id : "open",
        new_date_planned: date.toISOString()
      }
    }

    const url = pathname({ path, format })
    postRequest({ url, body }).then(res => {
      if (res.unprocessed) {
        setDropping(false)
        return
      }
      const newOpenEvents = state.openEvents.filter(wo => wo.info.workorder_id !== event.workorder_id)
      const changedEvent  = state.events.findIndex(ev => res.event.info.workorder_id === ev.info?.workorder_id)
      const newEvents = [...state.events]
      if (changedEvent !== -1) {
        newEvents[changedEvent] = res.event
      } else {
        newEvents.push(res.event)
      }
      setState(prevState=>({...prevState, openEvents: newOpenEvents, events: newEvents}))
      setDropping(false)
    })
  }

  const customButtons = {}
  const calendarViews = {}

  for (const view in CALENDAR_VIEWS) {
    calendarViews[view] = {id: view, name: I18n.t(`preferences.calendar.view_${view}`)}
  }
  
  if (TOOLS.newAbsences) {
    const path = '/absences/new'
    const url = pathname({ path, format: 'js' })
    customButtons.absence = {
      text: I18n.t('calendar.new_absence_button'),
      click: () => modalRequest(url)
    }
  }

  const filterEvents = () => {
    if (!state.events) return
    let visibleEvents = [...state.events] 
    const filteredResources = TOOLS.resources.filter(res => !filter.unchecked.includes(res.id))
    const resourcesIds = filteredResources.map(resource => resource.id)

    if (filter.view.name == CALENDAR_VIEWS.agenda) {
      visibleEvents = visibleEvents.filter(event =>resourcesIds.includes(event.resourceId.toString()))
    }
    return setBackgroundColor(visibleEvents)
  }

  const setBackgroundColor = events => {
    if (!events) return

    const isAbsence   = event => event.className.includes('absence-event')
    const isAgenda    = filter.view.name == CALENDAR_VIEWS.agenda
    const isAgendaDay = filter.view.name == CALENDAR_VIEWS.agenda_day

    events.forEach(event => {
      if (!isAgenda && !isAbsence(event)) {
        event.backgroundColor = 'white'
        event.textColor       = '#646464'
      } else if ((!isAgenda && !isAgendaDay) && isAbsence(event)) {
        event.display = 'background'
      } else if (isAgenda && isAbsence(event)) {
        event.backgroundColor = event.user_color
      }
    })
    return events
  }

  const setView = view => {
    state.calendar.changeView(CALENDAR_VIEWS[view.id])
    setFilter(prevFilter=>({...prevFilter, view: getCurrentView(CALENDAR_VIEWS[view.id], TOOLS.slotWidthWeek, TOOLS.slotWidthMonth)}))
  }

  return (
    <div className="calendar-right">
      { filter.showOpen && TOOLS.displayOpen == SEPARATED &&
         <OpenEventsList />
      }

      <div className="fullcalendar-wrapper">
        <Combobox
          options         = {Object.values(calendarViews)}
          onSelectElement = {setView}
          value           = {Object.keys(CALENDAR_VIEWS).find(key => CALENDAR_VIEWS[key] == filter.view.name)}
          className       = 'select-view'
        />
        <FullCalendar
          schedulerLicenseKey   = {TOOLS.license}
          timeZone              = {TOOLS.timeZone}
          height                = { filter.showOpen ? 'calc(100vh - 300px)' : 'calc(100vh - 130px)' }
          resourceOrder         = 'open,title'
          plugins               = {
            [
              scrollGrid,
              interactionPlugin,
              resourceTimelinePlugin,
              resourceTimeGridPlugin,
              timeGridPlugin,
              daygridplugin,
              resourceDayGridPlugin,
              resource
            ]
          }
          initialView           = {TOOLS.initialView}
          initialDate           = {new URLSearchParams(window.location.search).get('selected_date')}
          headerToolbar         = {{
            left:   `prev,next,today${TOOLS.newAbsences ? ',absence' : ''}`,
            center: 'title',
            right:  ''
          }}
          views                 = {{ assignment: {type: 'resourceTimelineWeek'} }}
          buttonText            = {{
            today:            I18n.t("workorders.list_of_the_day"),
            resourceTimeline: I18n.t('preferences.calendar.view_day'),
            timeGridWeek:     I18n.t('preferences.calendar.view_agenda'),
            day:              I18n.t('preferences.calendar.view_agenda_day'),
            week:             I18n.t('preferences.calendar.view_week'),
            assignment:       I18n.t('preferences.calendar.view_assignment'),
            month:            I18n.t('preferences.calendar.view_month')
          }}
          weekNumberCalculation ="ISO"
          slotMinTime           = {`${filter.slotMinTime}:00:00`}
          slotMaxTime           = {`${filter.slotMaxTime}:00:00`}
          resourceAreaWidth     ='15%'
          weekends              = {filter.showWeekends}
          events                = {filterEvents()}
          resources             = {TOOLS.resources.filter(res => !filter.unchecked.includes(res.id))}
          datesSet              = {handleDatesSet}
          select                = {handleSelect}
          eventResize           = {handleEventResize}
          eventContent          = {renderEventContent}
          eventDrop             = {handleInternalDrop}
          eventOverlap          = {fullDayView()}
          drop                  = {handleExternalDrop}
          resourceLabelContent  = {renderLabelContent}
          eventMouseEnter       = {e => setState(prevState=>({...prevState, showSmallCard: e}))}
          eventMouseLeave       = {() => setState(prevState=>({...prevState, showSmallCard: false}))}
          slotMinWidth          = {filter.view.slotMinWidth}
          slotLabelInterval     = {filter.view.slotInterval}
          slotDuration          = {filter.view.slotDuration || '00:30:00'}
          eventTimeFormat       = {{ meridiem: false, hour12: false }}
          locale                = {I18n.locale}
          dayMinWidth           = {150}
          customButtons         = {customButtons}
          selectable
          eventResourceEditable
          nowIndicator
          allDaySlot
        />
      </div>
    </div>
  )
}


export default CalendarRight
