import React               from 'react'
import { useCalendar }     from './useCalendar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox            from '@shared/checkbox/checkbox'
import Combobox            from '@shared/combobox'
import SharedFlatpickr     from "@shared/flatpickr"

const Filter = () => {
  const { state, setState, TOOLS, filter, setFilter } = useCalendar()

  const TYPES = [
    { id: 'Reactive', name: I18n.t('workorders.workorder_types.Reactive') },
    { id: 'Preventive', name: I18n.t('workorders.workorder_types.Preventive') },
    { id: 'Installation', name: I18n.t('workorders.workorder_types.Installation') },
  ]

  const handleAgencySelect = agency => {
    let newResources = [...TOOLS.resources]

    if (agency.id) {
      const agencyResources    = newResources.filter(res => res.agencyId === agency.id)
      const nonAgencyResources = newResources.filter(res => res.agencyId !== agency.id)

      agencyResources.map(resource => resource.unchecked = false)
      nonAgencyResources.map(resource => resource.unchecked = true)
    } else {
      newResources.map(resource => resource.unchecked = false)
    }
    setFilter(prevFilter=>({...prevFilter, agency: agency.id || '' }))
  }

  const handleCategorySelect = category => {
    setFilter(prevFilter=>({...prevFilter, category: category.id || '' }))
  }

  return (
    <div className="calendar-filters">
      <div className = "calendar-filter-toggle" onClick={() => setState({...state, filterOpen: !state.filterOpen })}>
        {state.filterOpen ? <FontAwesomeIcon icon='caret-down' /> : <FontAwesomeIcon icon='caret-right' />}
        {I18n.t("workorders.filter.filters")}
      </div>

      <div className={`calendar-filter${state.filterOpen ? ' filter-open' : ''}`}>
        <div className="form-group">
          <Combobox
            options         = {TYPES}
            onSelectElement = {type =>setFilter(prevFilter=>({...prevFilter, type: type.id  || '' } ))}
            placeholder     = {I18n.t("workorders.type")}
            prompt          = {I18n.t("workorders.type")}
            value           = {filter.type}
          />
        </div>

        { TOOLS.agencies?.length > 0 &&
          <div className="form-group">
            <Combobox
              options         = {filter.agencies}
              onSelectElement = {handleAgencySelect}
              placeholder     = {I18n.t("agencies.agency")}
              prompt          = {I18n.t("agencies.agency")}
              value           = {filter.agency}
            />
          </div>
        }

        <div className="form-group">
          <Combobox
            options         = {TOOLS.resources.filter(resource => !!resource.manager)}
            onSelectElement = {manager =>setFilter(prevFilter=>({...prevFilter, manager: manager.id  || '' } ))}
            placeholder     = {I18n.t("users.manager")}
            prompt          = {I18n.t("users.manager")}
            value           = {filter.manager}
          />
        </div>

        { TOOLS.categories?.length > 0 &&
          <div className="form-group">
            <Combobox
              options         = {filter.categories}
              onSelectElement = {handleCategorySelect}
              placeholder     = {I18n.t("categories.category")}
              prompt          = {I18n.t("categories.category")}
              value           = {filter.category}
            />
          </div>
        }

        {filter.site &&
          <div className="form-group">
            <input
              type="text"
              className="form-control mb-2 filter-site"
              value={filter.site_name}
              disabled
            />
            <FontAwesomeIcon icon="times" onClick={()=>setFilter(prevFilter=>({...prevFilter, site: '', site_name: ''}))}/>
          </div>
        }

        <div className="form-group">
          <input
            type="text"
            className="form-control mb-2 filter-search"
            value={filter.address || ""}
            placeholder={I18n.t("workorders.filter.location")}
            onChange={e=>setFilter(prevFilter=>({...prevFilter, address: e.currentTarget.value }))}
          />
          <FontAwesomeIcon icon="location-arrow" />
        </div>

        {filter.address &&
          <div className="radius">
            <div className="mb-1">{I18n.t("workorders.filter.radius")}</div>
            <input
              type="range"
              min="1"
              max="50"
              id="location-radius"
              value={filter.radius}
              onChange={e=>setFilter(prevFilter=>({...prevFilter, radius: e.currentTarget.value }))}
              className="slider"
            />
            <div className="text-right mb-2">{filter.radius || 2} km</div>
          </div>
        }
        <div className="form-group w-100" tooltip={I18n.t("workorders.filter.tooltip")}>
          <input
            type="text"
            className="form-control filter-search"
            value={filter.text || ""}
            placeholder={I18n.t("workorders.filter.search_placeholder")}
            onChange={e=>setFilter(prevFilter=>({...prevFilter, text: e.currentTarget.value }))}
          />
          <FontAwesomeIcon icon="search" />
        </div>

        {filter.showOpen &&
          <div className="period-input">
            <SharedFlatpickr
              options = {{
                mode:   "range",
                locale: I18n.locale,
                allowInput: true,
                dateFormat: "d/m",
                onClose: period=>setFilter(prevFilter=>({...prevFilter, period: period }))
              }}
              value={filter.period}
              className = "form-control mb-4 filter-search"
              placeholder={I18n.t("calendar.filter_open")}
            />
            <FontAwesomeIcon icon="calendar-alt" />
            { filter.period && <FontAwesomeIcon icon="times" onClick={() => setFilter(prevFilter=>({...prevFilter, period: '' }))}/> }
          </div>
        }

        { TOOLS.partsStatusPermitted &&
          <Checkbox
            checked   = {filter.showReceivedParts}
            id        = "parts-status-checkbox"
            onChange  = {()=>setFilter(prevFilter=>({...prevFilter, showReceivedParts: !filter.showReceivedParts }))}
            label     = {I18n.t("calendar.show_awaiting_parts_workorders")}
            className = "form-group"
          />
        }

        <Checkbox
          checked   = {filter.showOpen}
          id        = "open-checkbox"
          onChange  = {()=>setFilter(prevFilter=>({...prevFilter, showOpen: !filter.showOpen }))}
          label     = {I18n.t("calendar.show_open_workorders")}
          className = "form-group"
        />

        { filter.showOpen &&
          <Checkbox
            checked   = {filter.filterCalendar}
            id        = "filter-open"
            onChange  = {()=>setFilter(prevFilter=>({...prevFilter, filterCalendar: !filter.filterCalendar}))}
            label     = {I18n.t('workorders.filter.filter_calendar')}
            className = "form-group"
          />
        }
      </div>  
    </div>
  )
}

export default Filter
