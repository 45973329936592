import React from 'react';
import { pathname, postRequest } from '@react/helpers/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const QuestionNotFoundButton = ({ userLatestQuestion, setState }) => {
  const handleSendMessage = async () => {
    const body = { input: userLatestQuestion };
    const path = '/chatbot/chatbot_conversations';
    const url  = pathname({ path });
    
    setState((prevState) => ({
      ...prevState,
      isBotTyping: true,
      isChatConversationActive: true,
      isAssistantActive: false,
      chatBotmessages: [...prevState.chatBotmessages, { sender: 'user', text: userLatestQuestion }],
      input: ''
    }));
    
    postRequest({ url, body }).then(response => {
      setState((prevState) => ({
        ...prevState,
        isBotTyping: false,
        conversation_id: response.conversation_id,
        chatBotmessages: [...prevState.chatBotmessages, { sender: 'bot', text: response.message }]
      }));
    });
  }

  return (
    <div className="chat-bot__question-not-found chat-bot__question">
      <div style={{ marginRight: '13px'}}>{I18n.t('chatbot.extra_help_needed')}</div>
      <FontAwesomeIcon className="chat-bot__question-not-found__button" icon="comment" size="2x" onClick={handleSendMessage} />
    </div>
  );
};
