import React from 'react';
import sanitizeHtml from 'sanitize-html-react';
import { QuestionItem } from './QuestionItem';

export const QuestionsList = ({ questions, state, setState }) => {
  const language = I18n.locale;

  const handleClick = (question) => {
    setState((prevState) => ({
      ...prevState,
      currentSelectQuestion: question,
    }));
  };

  return (
    <div>
      {state.currentSelectQuestion ? (
        <div className='mt-2'>
          <div className="chat-bot__question">{ state.currentSelectQuestion[`question_title_${language}`] }</div>
          <div
            className="chat-bot__answer chat-bot__answer--expanded"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(state.currentSelectQuestion[`answer_${language}`]) }}
          />
        </div>
      ) : (
        questions && questions.map((question, idx) => (
          <QuestionItem
            key={idx}
            question={question}
            language={language}
            onSelect={handleClick}
          />
        ))
      )}
    </div>
  );
};
