import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MessageItem = ({ message, onSelect }) => {
  const handleClick = () => {
    onSelect(message);
  };

  return (
    <div className="chat-bot__assistant-question chat-bot__question" onClick={handleClick}>
      <div style={{ marginRight: '10px' }}>{ message.question }</div>
      <div className='chat-bot__question__arrow-right'>
        <FontAwesomeIcon icon='arrow-right' />
      </div>
    </div>
  );
};
