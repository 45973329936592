import React, { useState, useRef } from 'react'
import { bodyRequest, pathname }   from '@helpers/javascript/javascript'
import { convertKeysToSnake }      from "@react/helpers/helpers"
import Combobox                    from "@shared/combobox"
import Checkbox                    from '@shared/checkbox/checkbox'
import SharedFlatpickr             from "@shared/flatpickr"
import TextArea                    from "@shared/textarea"
import ShowAbsences                from "./ShowAbsences"

const NewAbsence = ({ technicians, mandatoryCategory, categories, frequencies }) => {
  const initialState = {
    userId:         '',
    allDay:         false,
    startDate:      new Date().toISOString(),
    endDate:        new Date().toISOString(),
    reason:         '',
    categoryId:     '',
    allTechnicians: false,
    recurrent:      false,
    interval:       1,
    frequency:      frequencies[0],
    maxEvents:      5
  }

  const formRef = useRef()
  const buttonRef = useRef()

  const [state, setState] = useState(initialState)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false)

  const showList = () => {
    if (state.userId) {
      setCalendarOpen(true)
    } else {
      formRef.current.querySelector(["input"]).reportValidity()
    }
  }

  const handleSubmit = (form, closeModal) => {
    if(!form.reportValidity()) return
    setIsSubmitting(true)
    let absence = { ...state }
                                              
    const path   = '/absences'
    const body   = {absence: convertKeysToSnake(absence)}

    const url = pathname({ path })
    bodyRequest({ url, body }).then(res => {
      document.getElementById('calendar-refetch').click()
      setState(initialState)
      if (closeModal) closeActiveModal()
      setIsSubmitting(false)
    }).catch(err => setIsSubmitting(false))
  }

  return (
    <>
      <form ref = { formRef }>
        <Combobox 
          options         = { technicians }
          onSelectElement = { tech => setState((prevState) => ({ ...prevState, userId: tech.id })) }
          placeholder     = { I18n.t("users.absences.select_user") }
          value           = { state.userId }
          label           = { I18n.t("workorders.technician") }
          className       = "form-group"
          required
        />

        <Checkbox
          checked   = {state.allDay}
          id        = "all-day"
          onChange  = { () => setState((prevState) => ({ ...prevState, allDay: !state.allDay })) }
          label     = {I18n.t("users.absences.all_day")}
          className = "form-group"
        />

        <div className= "d-flex f-gap-20">
          <div className = "form-group w-50">
            <label className = "control-label w-50" required>
              { I18n.t("users.absences.start_card") }
            </label>
            <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: !state.allDay,
                dateFormat: state.allDay ? "d/m/Y" : "d/m/Y - H:i"
              }}
              
              className   = "form-control flatpickr-nobg text-center"
              placeholder = { I18n.t("workorders.datetime_placeholder")}
              onChange    = { date => {setState((prevState) => ({ ...prevState, startDate: date[0].toISOString() }))} }
              onBlur      = { date => {setState((prevState) => ({ ...prevState, startDate: date[0].toISOString() }))} }
              value       = { new Date(state.startDate) }
            />
          </div>
          <div className = "form-group w-50">
            <label className = "control-label w-50" required>
              { I18n.t("users.absences.end_card") }
            </label>
            <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: !state.allDay,
                dateFormat: state.allDay ? "d/m/Y" : "d/m/Y - H:i",
                minDate:    state.startDate
              }}
              
              className   = "form-control flatpickr-nobg text-center"
              placeholder = { I18n.t("workorders.datetime_placeholder")}
              onChange    = { date => setState((prevState) => ({ ...prevState, endDate: date[0].toISOString() })) }
              value       = { new Date(state.endDate) }
            />
          </div>
        </div>

        <TextArea
          onChange     = { value => setState((prevState) => ({ ...prevState, reason: value })) }
          name         = "reason"
          defaultValue = { state.reason }
          label        = { I18n.t("users.absences.reason") }
          required     = { !mandatoryCategory }
        />

        { categories.length > 0 && 
          <div className='form-group'>
            <label 
              className="control-label" 
            >
              {I18n.t("categories.category")}
            </label>

            <Combobox 
              prompt          = { I18n.t('categories.select') }
              options         = { categories }
              onSelectElement = { category => setState((prevState) => ({ ...prevState, categoryId: category.id })) }
              value           = { state.categoryId }
              required        = { mandatoryCategory }
            />
          </div>
        }   

        <Checkbox
          checked   = {state.allTechnicians}
          id        = "all-techs"
          onChange  = { () => setState((prevState) => ({ ...prevState, allTechnicians: !state.allTechnicians })) }
          label     = {I18n.t("users.absences.all_technicians")}
          className = "form-group"
        />

        <div className='d-flex a-i-center'>
          <Checkbox
            checked   = {state.recurrent}
            id        = "recurrent"
            onChange  = { () => setState((prevState) => ({ ...prevState, recurrent: !state.recurrent })) }
            label     = {state.recurrent ? I18n.t("users.absences.repeat_every") : I18n.t("users.absences.repeat")}
            className = "form-group mr-2"
          />

          {
            state.recurrent && 
            <>
              <select
                className='form-control mr-2 m-0 w-10'
                value    = {state.interval}
                onChange = {num => setState((prevState) => ({ ...prevState, interval: num }))}
              >
                {[...Array(12)].map((_, i) => i + 1).map(i => <option key={i} value={i}>{i}</option>)}
              </select>

              <select
                className='form-control m-0 w-20'
                value    = {state.frequency}
                onChange = {freq => setState((prevState) => ({ ...prevState, frequency: freq }))}
              >
                { frequencies.map((frequency, index) =>
                  <option key={frequency} value={frequency}>
                    {I18n.t("maintenance.frequencies.singular")[index]}
                  </option>
                )}
              </select>
            </>
          }
        </div>

        <div className='d-flex ml-6 mt-2 a-i-center'>
          { state.recurrent &&
            <>
                <label className="pl-1 mr-2">{I18n.t("users.absences.max_events")}</label>
                <input 
                  className='form-control w-10'
                  name = "maxEvents"
                  type = "number" 
                  min  = {0} 
                  step = {1}
                  defaultValue = {state.maxEvents}
                  onChange = { max => setState((prevState) => ({ ...prevState, maxEvents: max })) }
                />
            </>
          }
        </div>

        <div className="d-flex mb-4">
          <div className="ml-auto">
            <div className = "btn btn-outline-default small ml-0 h-fit-content" data-dismiss="modal">
              {I18n.t('button.cancel')}
            </div>

            <div
              className="btn btn-primary small ml-2"
              onClick  = { showList }
              disabled = { !!isSubmitting }
              ref      = {buttonRef}
            >
              { I18n.t('users.absences.see') }
            </div>

            <div
              className="btn btn-primary small ml-2"
              onClick  = { () => handleSubmit(formRef.current, false) }
              disabled = { !!isSubmitting }
            >
              { I18n.t('button.save_and_create') }
            </div>

            <div
              className="btn btn-primary small ml-2"
              onClick  = { () => handleSubmit(formRef.current, true) }
              disabled = { !!isSubmitting }
            >
              { I18n.t('button.add') }
            </div>
          </div>
        </div>
      </form>

      <ShowAbsences
        calendarOpen      = {calendarOpen}
        setCalendarOpen   = {setCalendarOpen}
        userId            = {state.userId}
        buttonRef         = {buttonRef}
        categories        = {categories}
        mandatoryCategory = {mandatoryCategory}
      />
    </>
  )
}

export default NewAbsence
