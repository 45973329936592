import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modalContainer"];

  submit() {
    this._showSpinner();
  }

  // Private
  _showSpinner() {
    const modalContainer = this.modalContainerTarget
    const spinnerHTML =
      ` <div class="loading-spinner-container-sm pt-5">
          <div id="loading-spinner"></div>
        </div>
      `
    modalContainer.innerHTML = spinnerHTML
  }

}
