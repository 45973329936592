import React, { useRef, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitChatbotMessage } from './SubmitChatbotMessage';

export const ChatbotMessageList = ({ state, setState }) => {
  const messagesEndRef  = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [state.chatBotmessages]);

  const handleBackClick = () => {
    setState((prevState) => ({
      ...prevState,
      isAssistantActive: false,
      isChatConversationActive: false,
      chatBotmessages: [],
    }))
  };

  return(
    <div>
      <div className='chat-bot__chatbot-message-list-back-arrow'>
        <FontAwesomeIcon
          icon='arrow-left'
          className='chat-bot__assistant-back-arrow'
          onClick={handleBackClick}
        />
      </div>
      <div className='chat-bot__chatbot-message-list'>
        {state.chatBotmessages.map((msg, idx) => (
          <div
            key={idx}
            className={`chat-bot__wrapper__message ${
              msg.sender === 'user' ? 'chat-bot__wrapper__message--owned' : ''
            }`}
          >
            <div
              className="chat-bot__wrapper__message__bubble"
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(msg.text) }}
            />
          </div>
        ))}
        {state.isBotTyping && (
          <div className='chat-bot__wrapper__message chat-bot__wrapper__message--bot'>
            <div className="chat-bot__wrapper__message__bubble">
              <div className="mr-3 my-1">
                <div className="dot-elastic"/>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <SubmitChatbotMessage state={state} setState={setState} />
    </div>
  )
}
