import React from 'react';
import { pathname, postRequest } from '@react/helpers/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SubmitAssistantMessage = ({ state, setState, inputRef }) => {
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (state.input.trim() === '') return;

    setState((prevState) => ({
      ...prevState,
      userLatestQuestion: state.input,
      currentSelectTopic: null,
      isBotTyping: true,
    }));

    const body = { input: state.input, conversation_id: state.conversation_id };
    const path = '/chatbot/assistant_conversations';
    const url = pathname({ path });

    postRequest({ url, body })
      .then(response => {
        if(response.length) {
          handleAssistantResponse(response);
        } else {
          setState((prevState) => ({
            ...prevState,
            isBotTyping: false,
            isAssistantActive: true
          }));
        }
      })
  };

  const handleAssistantResponse = (response) => {
    const language = I18n.locale;
    response.forEach(topic => {
      const assistantMessages = topic.faq_questions.map(question => {
        return {
          id: question.id,
          question: question[`question_title_${language}`],
          answer: question[`answer_${language}`]
        };
      });
      setState((prevState) => ({
        ...prevState,
        isBotTyping: false,
        isAssistantActive: true,
        assistantMessages
      }));
    });
  };

  return (
    <form className="chat-bot__wrapper__body__input" onSubmit={handleSendMessage}>
      <input
        ref={inputRef}
        type="text"
        placeholder={I18n.t('chatbot.type_your_message')}
        value={state.input}
        disabled={state.isBotTyping}
        onChange={(e) => setState({ ...state, input: e.target.value })}
      />
      <button type="submit">
        <FontAwesomeIcon icon='paper-plane' className="chat-bot__wrapper__body__input-icon" />
      </button>
    </form>
  );
};
