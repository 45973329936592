import React                from 'react'
import { CalendarProvider } from './useCalendar';

// components
import Calendar                   from './calendar'

const App = ({ props }) => {
  return (
    <CalendarProvider props={props}>
      <Calendar />
    </CalendarProvider>
  ) 
}

export default App
