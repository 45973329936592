import React from 'react'
import { useCalendar } from './useCalendar';
import { bodyRequest, pathname } from "@react/helpers/fetch"

// components
import MiniCalendar from './miniCalendar'
import Filter       from './filter'
import TimeRange    from './timeRange'
import ResourceList from './resourceList'
import Checkbox     from '@shared/checkbox/checkbox'

const CalendarLeft = ({ 

}) => {
  const { state, setState, filter, setFilter, TOOLS } = useCalendar()
  const SEPARATED = "separated"

  const saveFilter = () => {
    const path   = '/calendar/save_filter'
    const body   = {
      filter: filter
    }

    const url = pathname({ path })
    bodyRequest({ url, body }).then(res => setState({...state, filterResponse: res.success }))
  }

  return(
    <div className="calendar-left">
      <div className="fixed-controls">
        <MiniCalendar />

        <TimeRange />

        <Checkbox
          checked   = {filter.showWeekends}
          id        = "weekend-checkbox"
          onChange  = {()=>setFilter(prevFilter=>({...prevFilter, showWeekends: !filter.showWeekends} ))}
          label     = {I18n.t("calendar.weekends")}
          className = "mt-3 form-group"
        />

        <Checkbox
          checked   = {filter.autoRefresh}
          id        = "auto-refresh-checkbox"
          onChange  = {()=>setFilter(prevFilter=>({...prevFilter, autoRefresh: !filter.autoRefresh} ))}
          label     = {I18n.t("calendar.auto_refresh")}
          className = "form-group"
        />

        { TOOLS.displayOpen == SEPARATED && <Filter /> }
        
        <ResourceList />

        <button className="btn btn-primary mb-4" onClick={saveFilter} disabled={!!state.filterResponse}>
          {!!state.filterResponse ? state.filterResponse : I18n.t("calendar.save_filter")}
        </button>
      </div>
    </div>
  )
}


export default CalendarLeft
