import React, { useState } from 'react';
import { AssistantMessageList } from './AssistantMessageList';
import { MessageShow } from './MessageShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AssistantDisplay = ({ state, setState }) => {
  const [currentSelectMessage, setCurrentSelectMessage] = useState(null);

  const handleBackClick = () => {
    setState((prevState) => ({
      ...prevState,
      isAssistantActive: false,
      input: '',
    }));
  };

  return (
    <div>
      {currentSelectMessage ? (
        <MessageShow 
          message={currentSelectMessage} 
          setCurrentSelectMessage={setCurrentSelectMessage} 
        />
      ) : (
        <div>
          <FontAwesomeIcon
            icon="arrow-left"
            className='chat-bot__assistant-back-arrow'
            onClick={handleBackClick}
          />
          <AssistantMessageList
            setCurrentSelectMessage={setCurrentSelectMessage}
            state={state}
            setState={setState}
          />
        </div>
      )}
    </div>
  );
};
