import React, { useEffect, useState, useRef } from 'react';
import { pathname, getRequest } from '@react/helpers/fetch';
import { SubmitAssistantMessage } from './assistant/SubmitAssistantMessage';
import { ChatbotMessageList } from './chatbot/ChatbotMessageList';
import { AssistantDisplay } from './assistant/AssistantDisplay';
import { TopicsList } from './faq/TopicsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ChatbotBox = () => {
  const chatBotRef = useRef(null);
  const inputRef   = useRef(null);

  const [state, setState] = useState({
    chatBotmessages: [],
    assistantMessages: [],
    userLatestQuestion: '',
    conversation_id: null,
    input: '',
    isBotTyping: false,
    isAssistantActive: false,
    isChatConversationActive: false,
    currentSelectTopic: null,
    currentSelectQuestion: null,
  });

  const handleBackClick = () => {
    if (state.currentSelectQuestion) {
      setState((prevState) => ({
        ...prevState,
        currentSelectQuestion: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        currentSelectTopic: null,
      }));
    }
  };

  return (
    <div>
      <button type="button"
        className="chat-bot__toggle-button"
        data-toggle="modal"
        data-target="#chatbox_modal"
      >
        <FontAwesomeIcon icon="comments" size="2x" />
      </button>

      <div class="modal fade" id="chatbox_modal" tabindex="1">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content chat-bot">
            <div className="modal-header chat-bot__wrapper__header">
              <h5 className="modal-title">{I18n.t('chatbot.welcome_message')}</h5>
            </div>
            <div className="modal-body" style={{ paddingTop: '10px' }}>
              <div className="chat-bot__wrapper" ref={chatBotRef}>
                <div className="chat-bot__wrapper__body">
                  <div className="chat-bot__wrapper__body__messages">
                    {state.currentSelectTopic ? (
                      <FontAwesomeIcon
                        icon="arrow-left"
                        className="chat-bot__assistant-back-arrow"
                        onClick={handleBackClick}
                      />
                    ) : (!state.isAssistantActive && <div style={{ height: '32px' }}></div>)}
                    {state.isBotTyping && !state.isChatConversationActive ? (
                      <div style={{ minHeight: '68.5vh'}}>
                        <div
                          className="chat-bot__wrapper__message chat-bot__wrapper__message--bot"
                          style={{ justifyContent: 'center' }}
                        >
                          <div className="chat-bot__wrapper__message__bubble">
                            <div className="mr-3 my-1">
                              <div className="dot-elastic" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : state.isAssistantActive ? (
                      <AssistantDisplay state={state} setState={setState} />
                    ) : state.isChatConversationActive ? (
                      <ChatbotMessageList state={state} setState={setState} />
                    ) : (
                      <TopicsList setState={setState} state={state} />
                    )}
                    {!state.isChatConversationActive && (
                      <SubmitAssistantMessage
                        state={state}
                        setState={setState}
                        inputRef={inputRef}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default ChatbotBox;
