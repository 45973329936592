import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modalContainer", "paymentLink"];

  connect() {
    document.addEventListener("top-up:requested", this.updateModalWithLink.bind(this));
  }

  spin() {
    this._showSpinner();
  }

  updateModalWithLink(event) {
    const paymentLinkUrl = event.detail?.payment_link_url;

    if (paymentLinkUrl) {
      this.modalContainerTarget.innerHTML =
        `
          <div class="text-center pt-5">
            <a href="${paymentLinkUrl}" target="_blank" class="btn btn-primary">
              ${I18n.t("account.wallets.top_up.payment_link_text")}
            </a>
          </div>
          <div class="modal-buttons pt-0 pt-4">
            <button class="btn btn-outline-default" data-dismiss="modal">
              ${I18n.t("button.close")}
            </button>
          </div>
        `;
    }
  }

  // Private
  _showSpinner() {
    const modalContainer = this.modalContainerTarget
    const spinnerHTML =
      ` <div class="loading-spinner-container-sm pt-5">
          <div id="loading-spinner"></div>
        </div>
      `
    modalContainer.innerHTML = spinnerHTML
  }

}
