import React, { useEffect, useState } from 'react';
import { pathname, getRequest } from '@react/helpers/fetch';
import { QuestionsList } from './QuestionsList';
import { TopicItem } from './TopicItem';

export const TopicsList = ({ state, setState }) => {
  const [topics, setTopics] = useState([]);
  const language = I18n.locale;

  useEffect(() => {
    const path = '/chatbot/topics';
    const url = pathname({ path });

    getRequest(url).then((response) => {
      setTopics(response);
    });
  }, []);

  const handleClick = (topic) => {
    setState((prevState) => ({
      ...prevState,
      currentSelectTopic: topic,
    }));
  };

  return (
    <div className='chat-bot__wrapper__message-list-container'>
      {state.currentSelectTopic ? (
        <QuestionsList 
          questions={state.currentSelectTopic.faq_questions}
          state={state} 
          setState={setState} 
        />
      ) : (
        topics.map((topic, idx) => (
          <TopicItem
            key={idx}
            topic={topic}
            language={language}
            onSelect={handleClick}
          />
        ))
      )}
    </div>
  );
};
