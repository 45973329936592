// Modules
import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Style
import './Modale.scss'

export const Modale = ({ children, closeModale }) => {

  const modaleRef = useRef(null)
  const closeRef  = useRef(null)

  const close = event => {
    if (!modaleRef.current.contains(event.target)) closeModale()
    if (event.currentTarget === closeRef.current)  closeModale()
  }

  return (
    <div className="modale__container" onClick={close}>
      <div className="modale__box" ref={modaleRef}>

        <div className="modale__close" onClick={close} ref={closeRef}>
          <FontAwesomeIcon icon="times" />
        </div>

        {children}

      </div>
    </div>
  )
}
