import React, { useEffect, useRef } from 'react'
import { Draggable } from "@fullcalendar/interaction"
import { jsRequest } from "@react/helpers/fetch"
import { useCalendar }                    from './useCalendar'
import { CALENDAR_VIEWS } from '@helpers/javascript/calendar_helper'

import EventCard from './eventCard'

const OpenEventsList = () => {
  const openEventsListRef = useRef(null)
  const draggableRef      = useRef(null)

  const { state } = useCalendar()
  
  useEffect(() => {
    if (!state.openEvents) return

    draggableRef.current = new Draggable(openEventsListRef.current, {
      itemSelector: ".draggable-event",
      appendTo: document.querySelector('.calendar-right'),
      eventData: function (eventEl) {
        const info = JSON.parse(eventEl.getAttribute('data-info'))
        let duration = info.duration
        let create = false
        let extendedProps = { info: info }
        return {
          duration,
          create,
          extendedProps,
        };
      },
    }, []);

    return () => {
      if (draggableRef.current) {
        draggableRef.current.destroy()
        draggableRef.current = null
      }
    };
  }, [state.openEvents])

  const openWorkorder = ev => {
    showModal('#show-workorder-modal')
    jsRequest(ev.info.show_url)
  }

  if (!state.calendar)   return null
  if (!state.openEvents) return null

  const isDraggable = state.calendar.currentData.viewSpec.type !== CALENDAR_VIEWS.agenda

  return (
    <div className="open-event-list-wrapper">
      <div ref={openEventsListRef} className='open-events-list my-2'>
        {state.openEvents.map(ev => {
          return (
            <div className={`fc-event top-event ${isDraggable ? 'draggable-event' : ''}`}
              style={{borderLeft: `4px solid ${ev.borderColor}`}}
              key={ev.info.workorder_id}
              data-info={JSON.stringify(ev.info)}
              onClick={() => openWorkorder(ev)}
            >
              <EventCard
                info            ={ev.info}
                showDate        ={false}
                showDuration
                externalCard
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OpenEventsList
