import React from 'react';
import { MessageItem } from './MessageItem';
import { QuestionNotFoundButton } from './QuestionNotFoundButton';

export const AssistantMessageList = ({ setCurrentSelectMessage, state, setState }) => {
  const handleSelectMessage = (msg) => {
    setCurrentSelectMessage(msg);
  };

  return (
    <div className='mt-2'>
      { state.assistantMessages.length ? (
        <div>
          <div className='chat-bot__wrapper__message-list-container'>
            {state.assistantMessages.map((msg, idx) => (
              <MessageItem key={idx} message={msg} onSelect={handleSelectMessage} />
            ))}
            <div className='chat-bot__wrapper__question-not-found-container'>
              <QuestionNotFoundButton
                userLatestQuestion={state.userLatestQuestion}
                setState={setState}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='chat-bot__wrapper__message-list-container'>
          <div className="chat-bot__assistant-question chat-bot__question">
            {I18n.t('chatbot.error_message')}
          </div>
        </div>
      )}
    </div>
  );
};
