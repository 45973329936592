import React from 'react'
import { useCalendar } from './useCalendar';

const TimeRange = () => {

  const { filter, setFilter } = useCalendar()

  return (
    <div id="calendar-multi-slider">
      <div className="text-center"></div>
      <div className="multi-slider">
        <input
          type="range"
          min="0"
          max="24"
          id="calendar-min-hour"
          value={filter.slotMinTime}
          onChange={(e)=>{ setFilter(prevFilter=>({...prevFilter, slotMinTime: e.target.value }))}}
          className="slider"
        />

        <input
          type="range"
          min="0"
          max="24"
          id="calendar-max-hour"
          value={filter.slotMaxTime}
          onChange={(e)=>{ setFilter(prevFilter=>({...prevFilter, slotMaxTime: e.target.value }))}}
          className="slider"

        />
      </div>
      <div className='text-center'>
        {filter.slotMinTime}h - {filter.slotMaxTime}h
      </div>
    </div>
  )
}

export default TimeRange