import React, { useState, useRef } from 'react'
import SharedFlatpickr             from "@shared/flatpickr"
import Combobox                    from "@shared/combobox"
import Checkbox                    from '@shared/checkbox/checkbox'
import TextArea                    from "@shared/textarea"
import { Modale }                  from '@components/Modale/Modale'
import { patchRequest, pathname }  from '@helpers/javascript/javascript'
import { convertKeysToSnake }      from "@react/helpers/helpers"

const EditAbsence = ({ 
  absence, 
  setEditAbsenceId, 
  categories, 
  mandatoryCategory,
  fetchAbsences
}) => {
  const initialState = {
    userId:         absence.user_id,
    allDay:         absence.all_day,
    startDate:      absence.start_date,
    endDate:        absence.end_date,
    reason:         absence.reason,
    categoryId:     absence.category?.id
  }

  const [state, setState] = useState(initialState)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const formRef = useRef()

  const handleSubmit = form => {
    if(!form.reportValidity()) return
    setIsSubmitting(true)
    let editedAbsence = { ...state }
                                              
    const path   = `/absences/${absence.id}`
    const body   = {absence: convertKeysToSnake(editedAbsence)}
    const url = pathname({ path })
    patchRequest({ url, body }).then(res => {
      document.getElementById('calendar-refetch').click()
      setEditAbsenceId(null)
      fetchAbsences()
    }).catch(err => setIsSubmitting(false))
  }
  
  return (
    <Modale closeModale={() => setEditAbsenceId(null)}>
      <form ref = { formRef } className='edit-absence p-3'>
        <h5>
          {I18n.t('users.absences.edit')}
        </h5>
        <Checkbox
          checked   = {state.allDay}
          id        = {`all-day-${absence.id}`}
          onChange  = { () => setState((prevState) => ({ ...prevState, allDay: !state.allDay })) }
          label     = {I18n.t("users.absences.all_day")}
          className = "form-group"
        />

        <div className= "d-flex f-gap-20">
          <div className = "form-group w-50">
            <label className = "control-label w-50" required>
              { I18n.t("users.absences.start_card") }
            </label>
            <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: !state.allDay,
                dateFormat: state.allDay ? "d/m/Y" : "d/m/Y - H:i"
              }}
              key         = { state.allDay }
              className   = "form-control flatpickr-nobg text-center"
              placeholder = { I18n.t("workorders.datetime_placeholder")}
              onChange    = { date => setState((prevState) => ({ ...prevState, startDate: date[0].toISOString() })) }
              value       = { new Date(state.startDate) }
            />
          </div>
          <div className = "form-group w-50">
            <label className = "control-label w-50" required>
              { I18n.t("users.absences.end_card") }
            </label>
            <SharedFlatpickr
              options={{
                locale:     I18n.locale,
                enableTime: !state.allDay,
                dateFormat: state.allDay ? "d/m/Y" : "d/m/Y - H:i",
                minDate:    new Date(state.startDate)
              }}
              key         = { state.allDay }
              className   = "form-control flatpickr-nobg text-center"
              placeholder = { I18n.t("workorders.datetime_placeholder")}
              onChange    = { date => setState((prevState) => ({ ...prevState, endDate: date[0].toISOString() })) }
              value       = { new Date(state.endDate) }
            />
          </div>
        </div>

        <TextArea
          onChange     = { value => setState((prevState) => ({ ...prevState, reason: value })) }
          name         = "reason"
          defaultValue = { state.reason }
          label        = { I18n.t("users.absences.reason") }
          required     = { !mandatoryCategory }
        />

        { categories.length > 0 && 
          <div className='form-group'>
            <label 
              className="control-label" 
            >
              {I18n.t("categories.category")}
            </label>

            <Combobox 
              prompt          = { I18n.t('categories.select') }
              options         = { categories }
              onSelectElement = { category => setState((prevState) => ({ ...prevState, categoryId: category.id })) }
              value           = { state.categoryId }
              required        = { mandatoryCategory }
            />
          </div>
        }   

        <div className="d-flex">
          <div className="ml-auto">
            <div 
              className = "btn btn-outline-default small ml-0 h-fit-content"
              onClick   = {() => setEditAbsenceId(null)} 
            >
              {I18n.t('button.cancel')}
            </div>
           
            <div
              className="btn btn-primary small ml-2"
              onClick  = { () => handleSubmit(formRef.current, true) }
              disabled = { !!isSubmitting }
            >
              { I18n.t('button.save') }
            </div>
          </div>
        </div>
      </form>
    </Modale>
  )
};

export default EditAbsence;
