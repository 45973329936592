// Uncomment when upgrading to rails 6
// require("turbolinks").start()
// require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("channels")

// To be moved to stimulus
import { formulaCalculatorInit } from '../application/contracts/calculator'
import { ordersInit }            from '../application/orders'

formulaCalculatorInit()
ordersInit()

// React in Rails — Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
