import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const QuestionItem = ({ question, language, onSelect }) => {
  return (
    <div className="chat-bot__assistant-question chat-bot__question" onClick={() => onSelect(question)}>
      <div style={{ marginRight: '10px' }}>{ question[`question_title_${language}`] }</div>
      <div className='chat-bot__question__arrow-right'>
        <FontAwesomeIcon icon='arrow-right' />
      </div>
    </div>
  );
};
