import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import { getCurrentView, CALENDAR_VIEWS } from '@helpers/javascript/calendar_helper'
import { getRequest, pathname }       from '@helpers/javascript/javascript'

const CalendarContext = createContext();

export const CalendarProvider = ({ children, props }) => {
  const didMountRef = useRef(false)
  const { filterSettings, preferences, toolSettings } = props

  const [throttle,      setThrottle]      = useState()

  const [filter, setFilter] = useState(() => ({
    unchecked:         filterSettings.unchecked,
    filterCalendar:    filterSettings.filterCalendar,
    showOpen:          filterSettings.showOpen,
    showReceivedParts: filterSettings.showReceivedParts,
    showWeekends:      filterSettings.showWeekends,
    autoRefresh:       filterSettings.autoRefresh,
    radius:            filterSettings.radius,
    text:              filterSettings.text,
    address:           filterSettings.address,
    period:            filterSettings.period,
    slotMinTime:       filterSettings.slotMinTime,
    slotMaxTime:       filterSettings.slotMaxTime,
    agency:            filterSettings.agency,
    manager:           filterSettings.manager,
    category:          filterSettings.category,
    site:              filterSettings.site,
    type:              filterSettings.type,
    view:              getCurrentView(filterSettings.view, preferences.slot_width_week, preferences.slot_width_month)
  }))

  const [state, setState] = useState({
    showPopup:             false,
    filterOpen:            false,
    filterResponse:        '',
    calendar:              null,
    currentDate:           null,
    startDate:             null,
    endDate:               null,
    showSmallCard:         false,
    events:                null,
    openEvents:            null
  })

  const TOOLS = ({
    slotWidthWeek:         preferences.slotWidthWeek,
    slotWidthMonth:        preferences.slotWidthMonth,
    workorderFromCalendar: preferences.workorderFromCalendar,
    displayOpen:           preferences.displayOpenWorkordersInCalendar,
    showPopovers:          preferences.showCalendarPopovers,
    partsStatusPermitted:  toolSettings.partsStatusPermitted,
    newAbsences:           toolSettings.newAbsences,
    license:               toolSettings.license,
    timeZone:              toolSettings.timeZone,
    categories:            toolSettings.categories,
    agencies:              toolSettings.agencies,
    resources:             toolSettings.resources,
    initialView:           filterSettings.view
  })

  const fetchEvents = ({start, end, callback, ignoreFilter}) => {
    const path   = '/calendar/event_list'
    const filterParams = {...filter}
    filterParams.view  = filter.view.name
    const params       = ignoreFilter ? {} : filterParams

    if (start && end) {
      params.start = start
      params.end   = end
    }

    const url = pathname({ path, params })
    getRequest(url).then(callback)
  }

  const fetchOpenEvents = () => {
    const path   = '/calendar/open_workorders'
    const params = filter
    const url    = pathname({ path, params })
    getRequest(url).then(res=>setState(prevState=>({...prevState, openEvents: res})))
  }

  const fetchAllEvents = () => {
    fetchEvents({
      start:        state.startDate,
      end:          state.endDate,
      callback:     (e)=>setState(prevState=>({...prevState, events: e})),
      ignoreFilter: !filter.filterCalendar
    })
  }

  useEffect(() => {
    if (didMountRef.current) {
      if (throttle) clearTimeout(throttle)
        setState(prevState=>({...prevState, filterResponse: ''}))
        
        setThrottle(setTimeout(() => {
          fetchAllEvents()
        if (filter.showOpen) fetchOpenEvents()
      }, 500))
    } else {
      if (filter.showOpen) fetchOpenEvents()
      didMountRef.current = true
    }
  }, [filter])

  useEffect(() => {
    if (!!filter.autoRefresh) {
      const refreshInterval = 15000
      const refetchEvents = setInterval(() => fetchEvents({
        start:    state.startDate,
        end:      state.endDate,
        callback: (e)=>setState(prevState=>({...prevState, events: e})),
      }), refreshInterval)
      return () => clearInterval(refetchEvents);
    }
  }, [filter.autoRefresh, state.startDate, state.endDate]);

  useEffect(() => {
    if (state.currentDate) {
      let url = new URL(window.location)
      url.searchParams.set('selected_date', state.currentDate.toISOString().split('T')[0]);
      history.replaceState(history.state, document.title, url.href)
    }
  }, [state.currentDate])

  return (
    <CalendarContext.Provider value={{ filter, setFilter, TOOLS, state, setState, fetchEvents, fetchAllEvents }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error('useCalendar must be used within a CalendarProvider');
  }
  return context;
};
