import React, { useState, useEffect, useRef } from "react"
import { getRequest, pathname }               from '@helpers/javascript/javascript'
import { CALENDAR_VIEWS, getCurrentView }     from '@helpers/javascript/calendar_helper'
import { UserLabel }                          from "@shared/calendar/userLabel"
import { ExistingEventCard }                  from './existingEventCard'
import { NewEventCard }                       from './newEventCard'
import { skipWeekends }                       from "@react/helpers/helpers"
import FullCalendar                           from '@fullcalendar/react' // must go before plugins
import Combobox                               from '@shared/combobox'
import TooltipPopup                           from '../../../calendar/tooltip-popup'

// plugins
import interactionPlugin          from "@fullcalendar/interaction"
import resourceTimelinePlugin     from '@fullcalendar/resource-timeline'
import resourceTimeGridPlugin     from '@fullcalendar/resource-timegrid'
import scrollGrid                 from '@fullcalendar/scrollgrid'
import resourceDayGridPlugin      from '@fullcalendar/resource-daygrid'
import resource                   from '@fullcalendar/resource'
import timeGridPlugin             from '@fullcalendar/timegrid'
import daygridplugin              from '@fullcalendar/daygrid'

export const Calendar = ({ calendar, setWorkorderState, workorderState, calendarPreferences, maximumDuration }) => {
  const { 
    passages, 
    technicianId, 
    title, 
    description, 
    predictedDuration, 
    datePlanned, 
    secondaryTechniciansIds
  } = workorderState

  const [fetchedEvents, setFetchedEvents] = useState([])
  const [events,        setEvents]        = useState([])
  const [resources,     setResources]     = useState([])
  const [fetching,      setFetching]      = useState()
  const [currentView,   setCurrentView]   = useState({})
  const [showPopup,     setShowPopup]     = useState(false)
  const BORDER_COLOR = "#ffe007"
  const calendarRef = useRef()
  const calendarViews = {}

  for (const view in CALENDAR_VIEWS) {
    calendarViews[view] = { id: view, name: I18n.t(`preferences.calendar.view_${view}`) }
  }

  const renderLabelContent = resource => {
    return <UserLabel resource = { resource } popupAction = { setShowPopup } />
  }

  useEffect(() => {
    const path   = '/calendar/tech_list'
    const format = 'json'
    const url    = pathname({ path, format })
    getRequest(url).then(setResources)
  }, [])

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(datePlanned)
  }, [datePlanned])

  const fullDayView = () => [CALENDAR_VIEWS.assignment, CALENDAR_VIEWS.month].includes(currentView.name)

  const renderEventContent = eventInfo => {
    const absence = eventInfo.event._def.extendedProps.absence_id
    const info    = eventInfo.event._def.extendedProps.info

    if (absence) {
      return (
        <div>{eventInfo.event._def.title}</div>
      )
    } else if (info) {
      return (
        <ExistingEventCard
          info = { info }
        />
      )
    } else {
      eventInfo.borderColor = BORDER_COLOR

      return <NewEventCard 
        eventInfo           = { eventInfo }
        workorderState      = { workorderState }
        calendarPreferences = { calendarPreferences }
      />
    }
  }

  const fetchEvents = ({ start, end, callback }) => {
    const path   = '/calendar/event_list'
    const params = {}

    if (start && end) {
      params.start = start
      params.end   = end
    }

    const url = pathname({ path, params })
    getRequest(url).then(callback)
  }

  const getEndDate = (startDate, duration) => {
    const endDate = new Date(startDate)
    endDate.setMinutes(endDate.getMinutes() + duration)
    return endDate
  }

  const firstPassage = id => {
    return ({
      title:       title ? title : description, 
      start:       datePlanned,
      end:         getEndDate(datePlanned, predictedDuration),
      resourceId:  id,
      borderColor: BORDER_COLOR,
      className:   secondaryTechniciansIds?.length ? '' : 'first-passage'
    })
  }

  const composeEvents = () => {
    let copiedPassages = passages.map(passage => (
      { 
        title:       title ? title : description, 
        start:       passage.datePlanned, 
        end:         getEndDate(passage.datePlanned, passage.predictedDuration), 
        resourceId:  technicianId,
        borderColor: BORDER_COLOR
      }
    ))
    if (secondaryTechniciansIds) {
      let secondaryPassages = []
      secondaryTechniciansIds.forEach(id => {
        copiedPassages.forEach(passage => {
          let copiedPassage = { ...passage }
          copiedPassage.resourceId = id
          secondaryPassages.push(copiedPassage)
        })
      })
      copiedPassages = copiedPassages.concat(secondaryPassages)
    }
    const allEvents = fetchedEvents.concat(copiedPassages)
    allEvents.push(firstPassage(technicianId))
    if (secondaryTechniciansIds) {
      secondaryTechniciansIds.forEach(id => {
        allEvents.push(firstPassage(id))
      })
    }
    allEvents.forEach(event => {
      event.backgroundColor = 'white'
    })
    setEvents(allEvents)
  }

  const handleDatesSet = e => {
    if(fetching) clearTimeout(fetching)

    setFetching(setTimeout(() => { fetchEvents({start: e.start, end: e.end, callback: setFetchedEvents}) }, 500))
    setCurrentView(getCurrentView(e.view.type, calendarPreferences.weekWidth, calendarPreferences.monthWidth))
  }

  const handleSelect = e => {
    setWorkorderState(
      { 
        ...workorderState, 
        technicianId: e.resource?._resource?.id || '',
        datePlanned: e.start.toISOString(),
        predictedDuration: (e.end - e.start) / 60000,
        passages: updatePassages(new Date(datePlanned), e.start)
      }
    )
  }

  const handleEventResize = e => {
    const isMainEvent = e.oldEvent.start.toISOString() == new Date(datePlanned).toISOString()
    const eventDuration = (e.event.end.getTime() - e.event.start.getTime()) / 60000

    if (eventDuration > maximumDuration) {
      e.revert()
      return alert(I18n.t('workorders.max_duration'))
    }

    if (isMainEvent) {
      setWorkorderState(
        { 
          ...workorderState, 
          datePlanned: e.event.start.toISOString(),
          predictedDuration: eventDuration
        }
      )
    } else {
      const copiedPassages = [...passages]
      const passageToUpdate = copiedPassages.find(({datePlanned}) => datePlanned == e.oldEvent.start.toISOString())
      passageToUpdate.predictedDuration = eventDuration

      setWorkorderState(
        { 
          ...workorderState, 
          passages: copiedPassages
        }
      )
    }
  }

  const updatePassages = (oldStartTime, newStartTime) => {
    const copiedPassages = [...passages]
    const intervalArray  = copiedPassages.map((passage, index) => {
      if (index === 0) {
        return new Date(passage.datePlanned) - new Date(datePlanned)
      } else {
        return new Date(passage.datePlanned) - new Date(copiedPassages[index - 1].datePlanned)
      }
    }) 

    for (let i=0; i<copiedPassages.length;i++) {
      if (new Date(copiedPassages[i].datePlanned).toISOString() == oldStartTime.toISOString()) {
        copiedPassages[i].datePlanned = skipWeekends(moment(newStartTime))
      } else if (new Date(copiedPassages[i].datePlanned) > oldStartTime) {
         if (i == 0) {
          copiedPassages[i].datePlanned = skipWeekends(moment(new Date(newStartTime.getTime() + intervalArray[i])))
        } else {
          copiedPassages[i].datePlanned = skipWeekends(moment(new Date(new Date(copiedPassages[i - 1].datePlanned).getTime() + intervalArray[i])))
        }
      } 
    }

    return copiedPassages
  }

  const handleEventDrop = e => {
    const isMainEvent = e.oldEvent.start.toISOString() == new Date(datePlanned).toISOString()
    const isMainTechnician = e.oldEvent._def.resourceIds[0] == technicianId
    const updateSecondaryTechnicians = () => {
      const secondaryTechnicians = [...secondaryTechniciansIds]
      const technicianIndex = secondaryTechnicians.indexOf(parseInt(e.oldEvent._def.resourceIds[0]))
      secondaryTechnicians[technicianIndex] = parseInt(e.event._def.resourceIds[0])
       
      return secondaryTechnicians
    }

    setWorkorderState(
      { 
        ...workorderState, 
        technicianId: isMainTechnician ? e.event._def.resourceIds[0] : technicianId,
        secondaryTechniciansIds: isMainTechnician ? secondaryTechniciansIds : updateSecondaryTechnicians(),
        datePlanned: isMainEvent ? e.event.start.toISOString() : datePlanned,
        predictedDuration: isMainEvent ? (e.event.end - e.event.start) / 60000 : predictedDuration,
        passages: updatePassages(e.oldEvent.start, e.event.start)
      }
    )
  }

  useEffect(() => {
    composeEvents()
  }, [fetchedEvents, passages, secondaryTechniciansIds, predictedDuration])

  return (
    <div className = "calendar-wrapper workorder-form-calendar w-85 m-0">
        <TooltipPopup showPopup = { showPopup } />
      <div className = "calendar-right workorder-calendar">
        <Combobox
          options         = { Object.values(calendarViews) }
          onSelectElement = { view => { calendarRef.current.getApi().changeView(CALENDAR_VIEWS[view.id]) } }
          value           = { Object.keys(CALENDAR_VIEWS).find(key => CALENDAR_VIEWS[key] == CALENDAR_VIEWS[calendar.calendarView]) }
          className       = 'select-view'
        />
        <FullCalendar
          ref                   = { calendarRef }
          schedulerLicenseKey   = { calendar.license }
          resourceOrder         = 'title'
          plugins               = {
            [
              scrollGrid,
              interactionPlugin,
              resourceTimelinePlugin,
              resourceTimeGridPlugin,
              timeGridPlugin,
              daygridplugin,
              resourceDayGridPlugin,
              resource
            ]
          }
          initialView           = { CALENDAR_VIEWS[`${calendar.calendarView}`] }
          headerToolbar         = {{
            left:   'prev,next,today',
            center: 'title',
            right:  ''
          }}
          views                 = {{ assignment: {type: 'resourceTimelineWeek'} }}
          buttonText            = {
            {
              today:            I18n.t("workorders.list_of_the_day"),
              resourceTimeline: I18n.t('preferences.calendar.view_day'),
              timeGridWeek:     I18n.t('preferences.calendar.view_agenda'),
              day:              I18n.t('preferences.calendar.view_agenda_day'),
              week:             I18n.t('preferences.calendar.view_week'),
              assignment:       I18n.t('preferences.calendar.view_assignment'),
              month:            I18n.t('preferences.calendar.view_month')
            }
          }
          weekNumberCalculation ="ISO"
          slotMinTime           = { `${calendar.calendarStart}:00:00` }
          slotMaxTime           = { `${calendar.calendarEnd}:00:00`  }
          resourceAreaWidth     ='15%'
          weekends              = { calendar.showWeekend }
          events                = { events }
          eventContent          = { renderEventContent }
          eventOverlap          = { fullDayView() }
          resources             = { resources }
          datesSet              = { handleDatesSet }
          select                = { handleSelect }
          resourceLabelContent  = { renderLabelContent }
          eventDrop             = { handleEventDrop }
          eventResize           = { handleEventResize }
          slotMinWidth          = { currentView.slotMinWidth }
          slotLabelInterval     = { currentView.slotInterval }
          slotDuration          = { currentView.slotDuration || '00:30:00' }
          eventTimeFormat       = { { meridiem: false, hour12: false } }
          locale                = { I18n.locale }
          dayMinWidth           = { 150 }
          initialDate           = { datePlanned }
          selectable
          eventResourceEditable
          editable
          nowIndicator
          allDaySlot
          droppable
        />
      </div>
      {
        !technicianId && (
          <div className = "action-message">
            { I18n.t("workorders.action_message") }
          </div>
        )
      }
    </div>
  )
}