import React           from 'react'
import SharedFlatpickr from "@shared/flatpickr"
import { useCalendar } from './useCalendar';

const MiniCalendar = () => {
  const { state, filter } = useCalendar()

  const goToDate = dateArray => {
    const [date] = dateArray
    state.calendar.gotoDate(moment(date).format('YYYY-MM-DDTHH:mm:ss'))
  }

  const disableWeekends = date => {
    return filter.showWeekends ? false : (date.getDay() === 0 || date.getDay() === 6);
  }

  return (
    <div className="flatpickr-wrapper">
      <SharedFlatpickr
        value={state.currentDate || new Date()}
        onChange={goToDate}
        options={{
          inline: true,
          altInput: true,
          altInputClass: "hidden",
          disable: [disableWeekends],
          locale: I18n.locale
        }}
      />
    </div>
  )
}

export default MiniCalendar
