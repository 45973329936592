import React, { useState } from 'react'
import QuillInput  from '@shared/quillInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Combobox                    from "@shared/combobox"

import { pathname, patchRequest } from '@react/helpers/fetch'

const formatDate  = date    => moment(date).format("DD/MM/YYYY - HH:mm")
const PROMOTED    = 'promoted'
const IGNORED     = 'ignored'

const manageCandidateQuestion = (props) => {
  const [candidateQuestion, setCandidateQuestion] = useState(props.candidate_question);
  const [user] = useState(props.user);
  const [faqTopics] = useState(props.faq_topics);

  const transformedTopics = faqTopics.map(topic => ({
    id: topic.id,
    name: topic.topic_name_fr,
    ...topic,
  }));

  const promoteCandidate = () => {
    const body = { candidate_question: candidateQuestion }
    const url = pathname({ path: `/candidate_questions/${candidateQuestion.id}/promote` })
    patchRequest({ url, body })
      .then(() => {
        setCandidateQuestion((prevState) => ({ ...prevState, status: PROMOTED }))
        flashNotice(I18n.t('candidate_questions.promoted.success'))
      }).catch(() => {
        flashAlert(I18n.t('errors.messages.oops'))
      })
  }

  const ignoreCandidate = () => {
    const url = pathname({ path: `/candidate_questions/${candidateQuestion.id}/ignore` })
    patchRequest({ url })
      .then(() => {
        setCandidateQuestion((prevState) => ({ ...prevState, status: IGNORED }))
        flashNotice(I18n.t('candidate_questions.ignored.success'))
      }).catch(() => {
        flashAlert(I18n.t('errors.messages.oops'))
      })
  }

  const isPromoted = () => {
    return candidateQuestion.status === PROMOTED
  }

  const isIgnored = () => {
    return candidateQuestion.status === IGNORED
  }

  const selectedTopic = () => {
    return transformedTopics.find(topic => topic.id === candidateQuestion.faq_topic_id)?.name
  }

  return(
    <div className="d-contents">
      <div className="text-center pr-2">
        <p>{ formatDate(candidateQuestion.createdAt) }</p>
        <p>{ user?.email }</p>
      </div>

      <div className="pr-2">
        <label>{ I18n.t('candidate_questions.topic') }</label>
        <Combobox 
          prompt          = { selectedTopic() }
          options         = { transformedTopics }
          onSelectElement = { faqTopic => setCandidateQuestion((prevState) => ({ ...prevState, faq_topic_id: faqTopic.id })) }
          value           = { candidateQuestion.faq_topic_id }
          required        = { true }
        />
        <label>{ I18n.t('candidate_questions.question') }</label>
        <input 
          className='form-control'
          name = "question"
          type = "string" 
          defaultValue = { candidateQuestion.question }
          onChange = { value => setCandidateQuestion((prevState) => ({ ...prevState, question: value })) }
        />
        <label>{ I18n.t('candidate_questions.answer') }</label>
        <QuillInput
          text={ candidateQuestion.answer }
          onChange={ value => setCandidateQuestion((prevState) => ({ ...prevState, answer: value })) }
          name="answer"
        />
        <hr />
      </div>
      { (!isPromoted() && !isIgnored()) ? (<div className="text-center d-flex-center">
        <div className="pointer px-1" onClick={promoteCandidate}>
          <FontAwesomeIcon icon="check-circle" size='lg' style={{color: 'green'}} />
        </div>
        <div className="pointer px-1" onClick={ignoreCandidate}>
          <FontAwesomeIcon icon="times-circle" size='lg' style={{color: '#eb4d3d'}} />
        </div>
      </div>) : <div className="text-center d-flex-center" />}
    </div>
  )
}

export default manageCandidateQuestion
