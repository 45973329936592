import React, { useState } from 'react'
import CalendarSelector    from './CalendarSelector'
import AbsenceList         from './AbsenceList'

const ShowAbsences = ({
  calendarOpen, 
  setCalendarOpen, 
  userId, 
  buttonRef,
  categories, 
  mandatoryCategory
}) => {
  const [period, setPeriod] = useState(null)
  return (
    <>
      { calendarOpen && 
        <CalendarSelector 
          setCalendarOpen = { setCalendarOpen }
          setPeriod       = { setPeriod }
          buttonRef       = { buttonRef }
        /> 
      }
      { period && 
        <AbsenceList 
          period            = { period }
          userId            = { userId }
          categories        = { categories }
          mandatoryCategory = { mandatoryCategory }
        /> 
      }
    </>
  )
}

export default ShowAbsences
