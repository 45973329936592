import React from 'react';
import { pathname, postRequest } from '@react/helpers/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SubmitChatbotMessage = ({ state, setState }) => {
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (state.input.trim() === '') return;

    const body = { input: state.input, conversation_id: state.conversation_id };
    const path = '/chatbot/chatbot_conversations';
    const url  = pathname({ path });
    
    setState((prevState) => ({
      ...prevState,
      isBotTyping: true,
      chatBotmessages: [...prevState.chatBotmessages, { sender: 'user', text: state.input }],
      input: ''
    }));
    
    postRequest({ url, body }).then(response => {
      setState((prevState) => ({
        ...prevState,
        isBotTyping: false,
        conversation_id: response.conversation_id,
        chatBotmessages: [...prevState.chatBotmessages, { sender: 'bot', text: response.message }]
      }));
    });
  };

  return (
    <form className="chat-bot__wrapper__body__input" onSubmit={handleSendMessage}>
      <input
        type="text"
        placeholder={I18n.t('chatbot.type_your_message')}
        value={state.input}
        disabled={state.isBotTyping}
        onChange={(e) => setState({ ...state, input: e.target.value })}
      />
      <button type="submit">
        <FontAwesomeIcon icon='paper-plane' className="chat-bot__wrapper__body__input-icon" />
      </button>
    </form>
  );
};
