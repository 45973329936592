import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TopicItem = ({ topic, language, onSelect }) => {
  return (
    <div className="chat-bot__assistant-question chat-bot__question" onClick={() => onSelect(topic)}>
      <div>{ topic[`topic_name_${language}`] }</div>
      <div className='chat-bot__question__arrow-right'>
        <FontAwesomeIcon icon='arrow-right' />
      </div>
    </div>
  );
};
