import React           from 'react'
import { useCalendar } from './useCalendar'

import CalendarLeft  from './calendar-left'
import CalendarRight from './calendar-right'
import SmallCard     from './smallCard'
import TooltipPopup  from './tooltip-popup'

const Calendar = () => {
  const { state, fetchAllEvents } = useCalendar()

  return(
    <div>
      <div className="calendar-grid">
        <CalendarLeft />
        <CalendarRight />
        <SmallCard />
        <TooltipPopup showPopup={state.showPopup} />

        <button 
          className="hidden" 
          id='calendar-refetch' 
          onClick= {() => fetchAllEvents()}
        >
        </button>
      </div>
    </div>
  )
}

export default Calendar
