import React, { useEffect, useState }          from 'react'
import { getRequest, deleteRequest, pathname } from '@helpers/javascript/javascript'
import { FontAwesomeIcon }                     from '@fortawesome/react-fontawesome'
import EditAbsence                             from './EditAbsence'

const AbsenceList = ({period, userId, categories, mandatoryCategory}) => {
  const [absences, setAbsences] = useState([])
  const [editAbsenceId, setEditAbsenceId] = useState(null)

  useEffect(()=>{
    fetchAbsences()
  }, [period, userId])

  const fetchAbsences = () => {
    if (!period) return
    const path   = '/absences/list'
    const params = { period, user_id: userId }
    const format = 'json'
    const url    = pathname({ path, params, format })
    getRequest(url).then(res => setAbsences(res.absences))
  }

  const deleteAbsence = absenceId => {
    const path = `absences/${absenceId}`
    const url = pathname({ path })
    deleteRequest(url).then(res => {
      if (res.unprocessed) return
      let newAbsences = [...absences]
      newAbsences = newAbsences.filter(absence => absence.id != absenceId)
      setAbsences(newAbsences)
      document.getElementById('calendar-refetch').click()
    })
  }

  return (
    <>
      { absences.length == 0
      ? 
        <div className='absence-list-empty'>
          {I18n.t("users.absences.empty", { start: period[0].toLocaleDateString(I18n.locale), end: period[0].toLocaleDateString(I18n.locale) })}
        </div>
      :
        <div className='absence-list mb-4'>
          {
            absences.map(absence => {
              return (
                <div key={absence.id}>
                  {
                    editAbsenceId == absence.id &&
                      <EditAbsence 
                        absence           = {absence} 
                        setEditAbsenceId  = {setEditAbsenceId}
                        categories        = {categories}
                        mandatoryCategory = {mandatoryCategory}
                        fetchAbsences     = {fetchAbsences}
                      /> 
                  }
                  <div className='absence-card'>
                    <div>
                      <div>{I18n.t("users.absences.start_card")}</div>
                      <div>{I18n.t("users.absences.end_card")}</div>
                    </div>
                    <div>
                      <div>{absence.show_start_date}</div>
                      <div>{absence.show_end_date}</div>
                    </div>
                    <div>
                      <strong>{absence.reason}</strong>
                    </div>
                    <div className='category'>
                      { absence.category && 
                        <div className='badge' 
                          style={{backgroundColor: absence.category.background,
                                  color: absence.category.color
                                }}
                        >
                            {absence.category?.name}
                        </div>
                      }
                    </div>
                    <div className='absence-action edit'>
                      { absence.can_update && 
                        <FontAwesomeIcon 
                          icon="edit" 
                          onClick = {() => setEditAbsenceId(absence.id)}
                        /> 
                      }
                    </div>
                    <div className='absence-action trash'>
                      { absence.can_update && 
                        <FontAwesomeIcon 
                          icon = "trash" 
                          onClick = {() => deleteAbsence(absence.id)}
                        />
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      }
    </>
  )
}

export default AbsenceList
