import React, { useRef, useEffect } from 'react'
import SharedFlatpickr              from "@shared/flatpickr"

const CalendarSelector = ({ setCalendarOpen, setPeriod, buttonRef }) => {
  const calendarRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalendarOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  const onDateChange = (dates) => {
    if (dates.length < 2) return
    setPeriod(dates.map(date => date))
    setCalendarOpen(false)
  }

  return (
    <div 
      className="calendar-selector"
      ref={calendarRef}
      style={{
        top: `${buttonRef.current.offsetTop - 320}px`,
        left: `${buttonRef.current.offsetLeft -100}px`,
      }}
    >
      <SharedFlatpickr
        onChange={onDateChange}
        options={{
          inline: true,
          altInput: true,
          altInputClass: "hidden",
          mode: "range",
        }}
      />
    </div>
  )
};

export default CalendarSelector;
